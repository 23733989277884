<div class="row mb-1 mr-0 custom-border">
  <div class="col-md-1 custom-border-right p-1 text-center mb-1 mt-1 ">
    <img src="assets/product.png" width="100px" height="100px">
  </div>

  <!--  Details article-->
  <div class="col-md-5 custom-border-right mb-1 mt-1 ">
    <div class="col">
      <div class="row">
        <div class="col-md-1"><i class="fas fa-info-circle blue-color"></i></div>
        <div class="col-md-11">Code article</div>
      </div>
      <div class="row">
        <div class="col-md-1"><i class="fas fa-info-circle blue-color"></i></div>
        <div class="col-md-11">designation</div>
      </div>
      <div class="row">
        <div class="col-md-1"><i class="fas fa-dollar-sign blue-color"></i></div>
        <div class="col-md-11">prix HT</div>
      </div>
      <div class="row">
        <div class="col-md-1"><i class="fas fa-dollar-sign blue-color"></i></div>
        <div class="col-md-11">prix TTC</div>
      </div>
    </div>
  </div>

  <!--  details categorie-->
  <div class="col-md-3 custom-border-right mb-1 mt-1 ">
    <div class="col-md-10 text-center">
      <div class="row">
        <div class="col-md-12">
          <h3>Stock actuel</h3>
        </div>
        <div class="col-md-12">
          <h3>139</h3>
        </div>
      </div>
    </div>
  </div>

  <!--  boutons action-->
  <div class="col-md-3 mb-1 mt-1 ">
    <div class="row">
      <div class="col-md-12 text-center">
        <button type="button" class="btn btn-link" data-toggle="modal" data-target="#exampleModal">
          <i class="fab fa-stack-overflow blue-color"></i>&nbsp;Correction de stock
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true" autofocus>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Correction de stock</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form>
          <div class="form-group">
            <input type="text" class="form-control" placeholder="Quantite">
          </div>
          <div class="form-group">
            <select class="form-control">
              <option>Correction positive</option>
              <option>Correction negative</option>
            </select>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fas fa-ban"></i>&nbsp;Annuler</button>
        <button type="button" class="btn btn-primary"> <i class="fas fa-save"></i>&nbsp;Enregistrer</button>
      </div>
    </div>
  </div>
</div>
