<div class="row m-3">
  <div class="card col-md-4 text-center mr-3">
    <div class="col">
      <div class="mb-3 mt-3">
        <img src="assets/product.png" class="rounded-circle" width="200px" height="200px"/>
      </div>
      <div class="mb-3">
        <h2>Nom Prenom</h2>
        <small class="form-text text-muted">Adresse, Ville, 12345</small>
      </div>
    </div>
  </div>
  <div class="card col-md-7">
    <form class="mt-3">
      <div class="form-group">
        <input type="password" class="form-control" name="ancienmotdepasse" placeholder="Ancien mot de passe" [(ngModel)]="ancienMotDePasse">
      </div>
      <div class="form-group">
        <input type="password" class="form-control" name="nouveaumotdepasse" placeholder="Nouveau mot de passe"
               [(ngModel)]="changerMotDePasseDto.motDePasse">
      </div>
      <div class="form-group">
        <input type="password" class="form-control" name="confirmmotdepasse" placeholder="Confirmer mot de passe"
               [(ngModel)]="changerMotDePasseDto.confirmMotDePasse" >
      </div>
    </form>
    <div class="row">
      <div class="col-md-12 text-right">
        <button class="btn btn-danger mr-3" (click)="cancel()">
          <i class="fas fa-ban"></i>&nbsp;
          Annuler
        </button>
        <button class="btn btn-primary" (click)="changerMotDePasseUtilisateur()">
          <i class="fas fa-save"></i>&nbsp;
          Enregitrer
        </button>
      </div>
    </div>
  </div>
</div>
