import { Injectable } from '@angular/core';
import {UserService} from '../user/user.service';
import {UtilisateursService} from '../../../gs-api/src/services/utilisateurs.service';
import {UtilisateurDto} from '../../../gs-api/src/models/utilisateur-dto';
import {Observable, of} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilisateurService {

  constructor(
      private userService: UserService,
      private utilisateurService: UtilisateursService
      ) { }

      findAllUtilisateurs(): Observable<UtilisateurDto[]> {
          return this.utilisateurService.findAll();
        }
}
