<div class="col">
  <div class="row m-3">
    <div class="col-md-8 p-0">
      <h1>Liste des categories</h1>
    </div>
    <div class="col-md-4 text-right">
      <app-boutton-action
        (clickEvent)="nouvelleCategory()"
      ></app-boutton-action>
    </div>
  </div>
  <div class="col m-3">
    <div class="row col-md-12 alert alert-danger" *ngIf="errorMsgs">
      {{ errorMsgs }}
    </div>
    <div class="row col-md-12 custom-border mb-3 p-3" *ngFor="let cat of listCategories">
      <div class="col-md-3 custom-border-right">
        {{cat.code}}
      </div>
      <div class="col-md-4 custom-border-right">
        {{cat.designation}}
      </div>
      <div class="col-md-3">
        <div class="row">
          <div class="col-md-4">
            <button type="button" class="btn btn-link" (click)="modifierCategory(cat.id)">
              <i class="fas fa-pencil-alt"></i>&nbsp;Modifier
            </button>
          </div>
          <div class="col-md-4">
            <button type="button" class="btn btn-link text-danger" data-toggle="modal" data-target="#modalDeleteCat"
                    (click)="selectCatPourSupprimer(cat.id)">
              <i class="fas fa-trash-alt"></i>&nbsp;Supprimer
            </button>
          </div>
          <div class="col-md-4">
            <button type="button" class="btn btn-link ">
              <i class="far fa-list-alt"></i>&nbsp;Details
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row mb-3" *ngIf="listCategories.length > 0">
    <div class=" col-md-12 text-center">
      <app-pagination></app-pagination>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="modalDeleteCat" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel"
     aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Confirmation</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Etes vous-sur de vouloir supprimer cette categorie?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="annulerSuppressionCat()">
          <i class="fas fa-ban"></i>&nbsp;Annuler</button>
        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="confirmerEtSupprimerCat()">
          <i class="fas fa-trash-alt"></i>&nbsp;Supprimer</button>
      </div>
    </div>
  </div>
</div>

