<div class="row mb-1 mr-0 custom-border">
  <!--  Details article-->
  <div class="col-md-3 custom-border-right mb-1 mt-1 ">
    <div class="row text-center">
      <div class="col-md-11">12.12.2012</div>
    </div>
  </div>

  <!--  details categorie-->
  <div class="col-md-3 custom-border-right mb-1 mt-1 ">
    <div class="row">
      <div class="col-md-12 text-center">
        <h5>139</h5>
      </div>
    </div>
  </div>

  <div class="col-md-3 custom-border-right mb-1 mt-1 ">
    <div class="row">
      <div class="col-md-12 text-center">
        <h5>Sortie</h5>
      </div>
    </div>
  </div>

  <!--  boutons action-->
  <div class="col-md-3 mb-1 mt-1">
    <div class="row text-center">
      <h5>COMMANDE_CLIENT</h5>

    </div>
  </div>
</div>
