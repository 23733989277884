<div class="accordion" id="appMenuAccordion">
  <div class="card" *ngFor="let menu of menuProperties">
    <div class="card-header" id="menu{{menu.id}}">
      <h2 class="mb-0">
        <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                [attr.data-target]="'#collapse' + menu.id" aria-expanded="true" aria-controls="collapseOne">
          <i class="{{menu.icon}}"></i>&nbsp;
          {{menu.titre}}
        </button>
      </h2>
    </div>

    <div id="collapse{{menu.id}}" class="collapse" [attr.aria-labelledby]="'menu' + menu.id" data-parent="#appMenuAccordion">

      <div class="card">
        <ul class="list-group list-group-flush">
          <li class="list-group-item" *ngFor="let sousMenu of menu.sousMenu" [ngClass]="{'text-white bg-primary': sousMenu.active, '': !sousMenu.active}">
            <a href="javascript:void(0);" (click)="navigate(sousMenu)" style="color: inherit;">
              <i class="{{sousMenu.icon}}"></i>&nbsp;
              {{sousMenu.titre}}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
