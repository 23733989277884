<div class="col mb-3">
  <div class="col-md-12 mb-3">
    <img src="assets/product.png" class="rounded-circle" width="200px" height="200px" />
  </div>
  <hr>
  <div class="col-md-12">
    <div class="col-md-12 mb-3">
      <h2><i class="fas fa-info-circle blue-color"></i>&nbsp;Information de l'utilisateur</h2>
    </div>
    <div class="col-md-12">
      <form>
        <div class="row mb-3">
          <div class="col">
            <input type="text" class="form-control" placeholder="Nom">
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Adresse 1">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <input type="text" class="form-control" placeholder="Prenom">
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Adresse 2">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <input type="text" class="form-control" placeholder="E-mail">
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Ville">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <input type="text" class="form-control" placeholder="Telephone">
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Code postal">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            &nbsp;
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Pays">
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="col-md-12 text-right">
    <button class="btn btn-danger mr-3" (click)="cancel()">
      <i class="fas fa-ban"></i>&nbsp;
      Annuler
    </button>
    <button class="btn btn-primary" >
      <i class="fas fa-save"></i>&nbsp;
      Enregitrer
    </button>
  </div>

</div>
