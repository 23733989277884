<div class="col mb-3">
  <div class="col-md-12 mb-3 mt-3">
    <button (click)="fileInput.click()" style="background-color: transparent; border: none;">
      <img [src]="imgUrl" class="rounded-circle" width="200px" height="200px" />
      <input hidden type="file" #fileInput (change)="onFileInput(fileInput.files)">
    </button>
  </div>
  <hr>
  <div class="col-md-12">
    <div class="col-md-12 mb-3">
      <h2><i class="fas fa-info-circle blue-color"></i>&nbsp;Information du {{origin}}</h2>
    </div>
    <div class="alert alert-danger" *ngIf="errorMsg.length > 0">
      <div *ngFor="let msg of errorMsg">
        {{ msg }}
      </div>
    </div>
    <div class="col-md-12">
      <form>
        <div class="row mb-3">
          <div class="col">
            <input type="text" class="form-control" placeholder="Nom"
            name="nom" [(ngModel)]="clientFournisseur.nom">
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Adresse 1"
            name="adresse1" [(ngModel)]="adresseDto.adresse1">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <input type="text" class="form-control" placeholder="Prenom"
            name="prenom" [(ngModel)]="clientFournisseur.prenom"
            >
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Adresse 2"
                   name="adresse2" [(ngModel)]="adresseDto.adresse2">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <input type="email" class="form-control" placeholder="E-mail"
                   name="email" [(ngModel)]="clientFournisseur.mail">
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Ville"
                   name="ville" [(ngModel)]="adresseDto.ville">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <input type="text" class="form-control" placeholder="Telephone"
                   name="numtel" [(ngModel)]="clientFournisseur.numTel">
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Code postal"
                   name="codepostal" [(ngModel)]="adresseDto.codePostale">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            &nbsp;
          </div>
          <div class="col">
            <input type="text" class="form-control" placeholder="Pays"
                   name="pays" [(ngModel)]="adresseDto.pays">
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="col-md-12 text-right">
    <button class="btn btn-danger mr-3" (click)="cancelClick()">
      <i class="fas fa-ban"></i>&nbsp;
      Annuler
    </button>
    <button class="btn btn-primary" (click)="enregistrer()">
      <i class="fas fa-save"></i>&nbsp;
      Enregitrer
    </button>
  </div>

</div>
