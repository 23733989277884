<div class="row mb-1 mr-0 custom-border">
  <div class="col-md-1 custom-border-right p-1 text-center mb-1 mt-1 ">
    <img src="assets/product.png" width="100px" height="100px">
  </div>

  <!--  Details article-->
  <div class="col-md-5 custom-border-right mb-1 mt-1 ">
    <div class="col">
      <div class="row">
        <div class="col-md-1"><i class="fas fa-info-circle blue-color"></i></div>
        <div class="col-md-10">{{ utilisateurDto.nom }}</div>
      </div>
      <div class="row">
        <div class="col-md-1"><i class="fas fa-info-circle blue-color"></i></div>
        <div class="col-md-10">{{ utilisateurDto.prenom }}</div>
      </div>
      <div class="row">
        <div class="col-md-1"><i class="fas fa-phone-alt blue-color"></i></div>
        <div class="col-md-10">0011 22 33 44 55 66</div>
      </div>
    </div>
  </div>

  <!--  details categorie-->
  <div class="col-md-3 custom-border-right mb-1 mt-1 ">
    <div class="col">
      <div class="row">
        <div class="col-md-1"><i class="fas fa-home blue-color"></i></div>
        <div class="col-md-10">{{ utilisateurDto.adresse?.adresse1 }}&nbsp;{{ utilisateurDto.adresse?.adresse2 }}</div>
      </div>
      <div class="row">
        <div class="col-md-1"><i class="fas fa-map-marker-alt blue-color"></i></div>
        <div class="col-md-10">{{ utilisateurDto.adresse?.ville }}</div>
      </div>
      <div class="row">
        <div class="col-md-1"><i class="fas fa-globe-europe blue-color"></i></div>
        <div class="col-md-10">{{ utilisateurDto.adresse?.pays }}</div>
      </div>
    </div>
  </div>

  <!--  boutons action-->
  <div class="col-md-3 mb-1 mt-1 ">
    <div class="row">
      <div class="col-md-4">
        <button type="button" class="btn btn-link">
          <i class="fas fa-pencil-alt"></i>&nbsp;Modifier
        </button>
      </div>
      <div class="col-md-4">
        <button type="button" class="btn btn-link text-danger">
          <i class="fas fa-trash-alt"></i>&nbsp;Supprimer
        </button>
      </div>
      <div class="col-md-4">
        <button type="button" class="btn btn-link ">
          <i class="far fa-list-alt"></i>&nbsp;Details
        </button>
      </div>
    </div>
  </div>
</div>
