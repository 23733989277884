<div class="row mb-1 mr-0 custom-border">
  <div class="col-md-1 custom-border-right p-1 text-center mb-1 mt-1 ">
    <img src="assets/product.png" width="100px" height="100px">
  </div>

  <!--  Details article-->
  <div class="col-md-5 custom-border-right mb-1 mt-1 ">
    <div class="col">
      <div class="row">
        <div class="col-md-1"><i class="fas fa-info-circle blue-color"></i></div>
        <div class="col-md-10">{{ cltFrs?.nom }}</div>
      </div>
      <div class="row">
        <div class="col-md-1"><i class="fas fa-info-circle blue-color"></i></div>
        <div class="col-md-10">{{ cltFrs?.prenom }}</div>
      </div>
      <div class="row">
        <div class="col-md-1"><i class="fas fa-phone-alt blue-color"></i></div>
        <div class="col-md-10">{{ cltFrs?.numTel }}</div>
      </div>
    </div>
  </div>

  <!--  details commande-->
  <div class="col-md-3 custom-border-right mb-1 mt-1 ">
    <div class="col">
      <div class="row">
        <div class="col-md-1"><i class="fas fa-qrcode blue-color"></i></div>
        <div class="col-md-10">{{ commande.code }}</div>
      </div>
      <div class="row">
        <div class="col-md-1"><i class="fas fa-calendar-check blue-color"></i></div>
        <div class="col-md-10">{{ commande.dateCommande }}</div>
      </div>
      <div class="row">
        <div class="col-md-1"><i class="fas fa-cart-arrow-down blue-color"></i></div>
        <div class="col-md-10">{{ commande.etatCommande }}</div>
      </div>
    </div>
  </div>

  <!--  boutons action-->
  <div class="col-md-3 mb-1 mt-1 ">
    <div class="row">
      <div class="col-md-6 text-center">
        <button type="button" class="btn btn-link" (click)="modifierClick()">
          <i class="fas fa-pencil-alt blue-color"></i>&nbsp;
          Modifier
        </button>
      </div>
      <div class="col-md-6 text-center">
        <button type="button" class="btn btn-link">
          <i class="fas fa-trash-alt blue-color"></i>&nbsp;
          Supprimer
        </button>
      </div>
    </div>
  </div>
</div>
