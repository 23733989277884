<div class="btn-group" role="group" aria-label="Basic example">
  <button type="button" class="btn btn-light">
    <i class="fas fa-angle-double-left"></i>
  </button>
  <button type="button" class="btn btn-light">
    <i class="fas fa-angle-left"></i>
  </button>


  <button type="button" class="btn btn-light">1</button>
  <button type="button" class="btn btn-light">2</button>
  <button type="button" class="btn btn-light">3</button>
  <button type="button" class="btn btn-light">4</button>
  <button type="button" class="btn btn-light">5</button>
  <button type="button" class="btn btn-light">6</button>


  <button type="button" class="btn btn-light">
    <i class="fas fa-angle-right"></i>
  </button>
  <button type="button" class="btn btn-light">
    <i class="fas fa-angle-double-right"></i>
  </button>
</div>
