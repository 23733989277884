import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {UtilisateurDto} from '../../../../gs-api/src/models/utilisateur-dto';
import {UtilisateurService} from '../../../services/utilisateur/utilisateur.service';

@Component({
  selector: 'app-page-utilisateur',
  templateUrl: './page-utilisateur.component.html',
  styleUrls: ['./page-utilisateur.component.scss']
})
export class PageUtilisateurComponent implements OnInit {

  listUtilisateur: Array<UtilisateurDto> = [];

  constructor(
    private router: Router,
    private utilisateurService: UtilisateurService
  ) { }

  ngOnInit(): void {
    this.findListUtilisateur();
  }

  findListUtilisateur(): void {
      this.utilisateurService.findAllUtilisateurs()
      .subscribe(utilisateurs => {
        this.listUtilisateur = utilisateurs;
      });
    }

  nouvelUtilosateur(): void {
    this.router.navigate(['nouvelutilisateur']);
  }
}
