<div class="row m-3">
  <div class="card col-md-4 text-center mr-3">
    <div class="col">
      <div class="mb-3 mt-3">
        <img src="assets/product.png" class="rounded-circle" width="200px" height="200px"/>
      </div>
      <div class="mb-3">
        <h2>Nom Prenom</h2>
        <small class="form-text text-muted">Adresse, Ville, 12345</small>
      </div>
      <div class="col mb-3 p-0">
        <button type="button" class="btn btn-primary mr-3"><i class="fas fa-pencil-alt"></i>&nbsp;Update
          profil</button>
        <button type="button" class="btn btn-warning" (click)="modifierMotDePasse()"><i class="fas fa-lock"></i>&nbsp;Resset Password</button>
      </div>
    </div>
  </div>
  <div class="card col-md-7">
    <table class="table">
      <tbody>
      <tr>
        <th scope="row">Nom complet</th>
        <td>nom prenom</td>
      </tr>
      <tr>
        <th scope="row">Email</th>
        <td>adresse@email.com</td>
      </tr>
      <tr>
        <th scope="row">telephone</th>
        <td>011 22 33 44 55 66</td>
      </tr>
      <tr>
        <th scope="row">Adresse</th>
        <td>Adresse, Ville, 12345</td>
      </tr>
      <tr>
        <th scope="row">Entreprise</th>
        <td>Nom entreprise</td>
      </tr>
      </tbody>
    </table>
  </div>

</div>
