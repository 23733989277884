<div class="col mb-3">
  <div class="col-md-12">
    <div class="col-md-12 mb-3 mt-3">
      <h2>Nouvelle categorie</h2>
    </div>
    <div class="col-md-12">
      <div class="alert alert-danger" *ngIf="errorMsg.length > 0">
        <div *ngFor="let msg of errorMsg">
          <span >{{msg}}</span>
        </div>
      </div>
      <form>
        <div class="row mb-3">
          <div class="col">
            <input type="text" class="form-control" placeholder="Code category" name="codeCat" [(ngModel)]="categoryDto.code">
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            <input type="text" class="form-control" placeholder="Description" name="designationCat" [(ngModel)]="categoryDto.designation">
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="col-md-12 text-right">
    <button class="btn btn-danger mr-3" (click)="cancel()">
      <i class="fas fa-ban"></i>&nbsp;
      Annuler
    </button>
    <button class="btn btn-primary" (click)="enregistrerCategory()">
      <i class="fas fa-save"></i>&nbsp;
      Enregitrer
    </button>
  </div>

</div>
