<div class="col">
  <div class="row m-3">
    <div class="col-md-8 p-0">
      <h1>Liste des utilisateurs</h1>
    </div>
    <div class="col-md-4 text-right">
      <app-boutton-action
        (clickEvent)="nouvelUtilosateur()"
      ></app-boutton-action>
    </div>
  </div>
  <div class="col m-3">
    <app-detail-utilisateur *ngFor="let utilisateur of listUtilisateur" [utilisateurDto]="utilisateur" ></app-detail-utilisateur>
  </div>
  <div class="row mb-3">
    <div class=" col-md-12 text-center">
      <app-pagination></app-pagination>
    </div>
  </div>

</div>
