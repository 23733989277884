<div class="main-container">
  <div class="row min-height-600 no-flex-wrap">
    <div class="card col-md-2 mr-2">
      <div class="col pl-0 pr-0">
        <div class="card mt-3 mb-2 border-none text-center">
          <h4>Gestion de stock</h4>
          <small class="form-text text-muted">By Bernard SANOU</small>
        </div>
        <div>
          <app-menu></app-menu>
        </div>
      </div>
    </div>
    <div class="card col-md-10 pl-0 pr-0 backgroung-grey border-none">
      <div class="col pl-0 pr-0 d-flex flex-column justify-content-between">
        <div class="card p-3 min-height-80 mb-2">
          <app-header></app-header>
        </div>
        <div class="card mb-2" style="min-height: 480px">
          <app-loader></app-loader>
          <router-outlet></router-outlet>
        </div>
        <div class="card min-height-80" style="text-align : center">
          Application de gestion de stock v1.0
        </div>
      </div>
    </div>
  </div>
</div>
