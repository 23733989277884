<div class="row">
  <div class="col-md-8">
    <div class="input-group flex-nowrap">
      <input type="text" class="form-control" placeholder="Username" aria-label="Username" aria-describedby="addon-wrapping">
      <div class="input-group-prepend">
        <span class="input-group-text" id="addon-wrapping"><i class="fas fa-search"></i></span>
      </div>
    </div>
  </div>
  <div class="col-md-4">
    <div class="row">
      <div class="col-md-9 text-right">
        <span> {{connectedUser.nom}}</span>
      </div>

      <div class="col-md-3">
        <a href="javascript:void(0);" [routerLink]="['/profil']">
          <img src="favicon.ico" class="rounded-circle" />
        </a>
      </div>
    </div>
  </div>
</div>
